import React from "react";
import "./Error.css";

const Error = () => {
  return (
    <>
      <h1>404 Dispositivo no encontrado</h1>
      <p class="zoom-area"></p>
      <section class="error-container">
        <span class="four">
          <span class="screen-reader-text">4</span>
        </span>
        <span class="zero">
          <span class="screen-reader-text">0</span>
        </span>
        <span class="four">
          <span class="screen-reader-text">4</span>
        </span>
      </section>
      <div class="link-container"></div>
    </>
  );
};

export default Error;
