import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Device from "./Device";

const App = () => {
  const publicRoutes = (
    <Routes>
      <Route path="/" element={<>Hola</>} />
      <Route path="/:id" element={<Device />} />
    </Routes>
  );

  return (
    <>
      <BrowserRouter>{publicRoutes}</BrowserRouter>
    </>
  );
};

export default App;
